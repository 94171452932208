*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.deg-45{
  rotate: 45deg;
}

.emoji-404{
  position: relative;
  animation: mymove 2.5s infinite;
}

@keyframes mymove {
  33%   {top: 0px;}
  66%  {top: 20px;}
  100%  {top: 0px}
}

input::-webkit-inner-spin-button, input::-webkit-outer-spin-button{
  -webkit-appearance: none;
  margin: 0;
}